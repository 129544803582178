
.profile-header{
    position:fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 64px;
    padding: 0px;
}

.header-profile-name{
    color: #000000E0;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
}

.header-profile-name-computer{
    font-size: 24px;
    .header-profile-name()
}

.header-profile-name-mobile{
    font-size: 10px;
    .header-profile-name()
}

.custom-drawer{
    background: rgba(255, 255, 255, 0.8) !important;
}

.header-anchor{
    color: #FFFFFFD9;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;

    min-width: 0;
    width: 117px;
    background: none;
    padding-top: 0px;
}


.header-col2{
    display: flex;
    justify-content: right;
    align-items: center;

    .ant-anchor{
        justify-content: right;
    }
}

.profile-intro-container{
    .descriptions{
        transform: translateY(50vh);
    }
    .descriptions-mobile{
        transform: translateY(20vh);
    }
    .description-text-salutation{
        color: #c7aa36;
    }

    .description-text-salutation-mobile{
        background:rgba(255, 255, 255, 0.4);
        .description-text-salutation();
    }

    .description-text-description{
        font-weight: 800;
        margin: 0px;
    }

    .description-text-description-mobile{
        background:rgba(255, 255, 255, 0.4);
        .description-text-description();
    }

    .short-description{
        padding-top: 15px;
        line-height: 1.8;
        .short-description-text{
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
}

.profile-about-container{
    height: fit-content;
    background: rgba(255, 254, 240, 0.8);
    padding-top: 64px;

    .about-description{
        .hobbies{
            margin-top: 50px;
            font-weight: 600;
        }
    }

    .about-description-computer{
        padding: 30px;
        
        .about-description();
    }

    .about-description-mobile{
        padding: 5px 30px;
        .hobbies{
            font-size: 12px;
        }
        .about-description();
    }
}

.profile-about-container-computer{
    padding: 30px 0;
    .profile-about-container();

    .about-section-title{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase !important;
        color: #666;
    }

    .about-sections{
        .personal-info{
            .label-value-row-1{
                margin-top: 20px;
            }
            .about-label{
                color: #666;
                text-transform: capitalize;
                font-size: 15px;
                font-family: Open Sans, sans-serif;
                opacity: 0.8;

                font-weight: 500;
                line-height: 1.6;
            }
            .about-value{
                color: #c7aa36;
                text-transform: capitalize;
                font-size: 15px;
                font-family: Open Sans, sans-serif;

                font-weight: 500;
                line-height: 1.6;
            }
            .hobbies{
                font-size: 16px;
            }
        }
        .experience{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
        }
    }
}

.about-card{
    min-width: 150px;
    min-height: 60px;
    background-color: #f5f5f5;

    .ant-card-body{
        padding: 10px 20px 10px 20px;
    }
    .about-card-title{
        color: #c7aa36;
        font-size: 16px;
        font-weight: 700;
        font-family: Poppins, sans-serif;
        display: flex;
        justify-content: center;
    }
    .about-card-subtitle{
        color: #666;
        font-size: 10px;
        font-weight: 700;
        font-family: Poppins, sans-serif;
        display: flex;
        justify-content: center;
    }
}

.profile-about-container-mobile{
    padding: 10px 0;
    .profile-about-container();
}

.profile-experience-container{
    padding-top: 64px;
    padding-bottom: 64px;
}

.profile-contact-container{
    padding-top: 64px;
    background: rgba(255, 254, 240, 0.8);

    .contact-sections{
        padding-bottom: 64px;
        .contact-deatils{
            display: flex;
            flex-direction: column;
            gap: 15px;
            .contact-description{
                text-align: center;
                color: #666;
                font-size: 15px;
                font-family: Open Sans, sans-serif;
                opacity: 0.8;

                font-weight: 500;
                line-height: 1.6;
            }  
        }

        .email-fields{
            border: 1px solid yellow;
        }
    }
}

.contact-cards{
    display: flex;
    gap: 50px;
    justify-content: center;
}

.contact-card{
    background-color: unset;
    width: 25vw;

    .ant-card-body{
        padding: 0;
    }
    .contact-card-row{
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 10px;

        .contact-card-icon{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .contact-card-text{
            font-size: 15px;
            font-family: Open Sans, sans-serif;
            font-weight: 500;
            line-height: 1.6;
            color: #666;
    
            .contact-card-label{
                opacity: 0.8;
                margin: 0;
            }
            .contact-card-value{
                opacity: 1;
                font-weight: 700;
                margin: 0;
            }
        }
    }
}

.linked-in-button{
    padding: 0px;
    height: 0px;
    font-weight: 700;
}

.profile-footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 24px 48px;
    background: #232931;

    color: #FFFFFFD9;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;

}

.social-icons{
    font-size: 16px;
}

.skill-cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    align-items: left;
    padding-top: 10px;
}


.experience-cards{
    padding-top: 10px;
}

.experience-card{
    background-color: rgba(255, 254, 240, 0.8);
    .experience-content{
        width: calc( 100vw - 516px );
        
        .highlights-wrapper{
            margin-top: 20px;
            width: 100%;
            border: 1px solid #e0d484;
            padding: 15px;
            
            .highlights-text{
                color: #666;
                text-transform: capitalize;
                font-size: 14px;
                font-family: Open Sans, sans-serif;
                opacity: 0.8;
                font-weight: 500;
                line-height: 1.6;
            }   
            .higlights-bullet{
                padding-left: 28px;
                display: flex;
                align-items: center; 
                gap: 10px; 
                
                .higlights-bullet-image{
                    flex-shrink: 0;
                }
            }
        }
    }
}

.experience-designation{
    font-size: 18px;
    font-family: Poppins, sans-serif;
    text-transform: uppercase !important;
    font-weight: 500;
    line-height: 1.2;
}

.experience-card-tenure{
    font-size: 12px;
    font-weight: 600;
    opacity: 0.8;
}

.section-title{
    padding-bottom: 20px;
    text-align: center;
    color: #000000E0;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 900 !important;
    span{
        color: #c7aa36;
    }
}
